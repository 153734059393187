<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg">
            <v-col cols="12">
              <h3 class="primary--text font-weight-bold">
                ฟอร์มตั้งค่าเงื่อนไขระดับสมาชิก
              </h3>
              <v-divider class="mt-3" />
            </v-col>
            <v-col cols="12">
              <p>
                เงื่อนไขการแลกคะแนน
              </p>
              <div
                class="d-flex flex-row align-base-line"
                style="gap: 1rem;">
                <v-text-field
                  v-model.number="formData.baht"
                  color="primary"
                  type="number"
                  suffix="บาท"
                  outlined
                  dense
                  required />
                <span class="mt-2">
                  เท่ากับ
                </span>
                <v-text-field
                  v-model.number="formData.point"
                  color="primary"
                  type="number"
                  suffix="คะแนน"
                  outlined
                  dense
                  required />
              </div>
              <span
                class="error--text"
                style="font-size: 12px;">
                *ลูกค้าจะได้คะแนนก็ต่อเมื่อ สถานะเปลี่ยนเป็น ‘ชำระเงินเสร็จสิ้น’
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import PointRatesProvider from '@/resources/point-rates.provider'

const PointRatesService = new PointRatesProvider()

export default {
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      baht: 0,
      point: 0
    }
  }),
  mounted () {
    this.getItemLasted()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'SettingsMember' })
    },
    async getItemLasted () {
      try {
        this.loading = true

        const { data } = await PointRatesService.getLastItems()

        if (data) {
          this.formData = { ...data }
        }
      } catch (error) {
        console.error('getItemLasted', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        await PointRatesService.create(this.formData)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไขข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'SettingsMember' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
