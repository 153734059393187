import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class PointRatesProvider extends HttpRequest {
  getLastItems () {
    this.setHeader(getAuthToken())
    return this.get('/point-rates/last')
  }

  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/point-rates', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/point-rates/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/point-rates', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/point-rates/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/point-rates/${id}`)
  }
}

export default PointRatesProvider
